<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <polygon
      style="fill:#F64B4A;"
      points="409.547,112.778 102.453,112.778 64.74,512 447.26,512 "
    />
    <path
      style="fill:#FED159;"
      d="M351.967,198.275c-5.046,0-9.134-4.09-9.134-9.134v-84.04c0-47.88-38.953-86.833-86.833-86.833
	s-86.833,38.953-86.833,86.833v84.04c0,5.044-4.09,9.134-9.134,9.134s-9.134-4.09-9.134-9.134v-84.04
	C150.899,47.148,198.047,0,256,0s105.101,47.148,105.101,105.101v84.04C361.101,194.185,357.011,198.275,351.967,198.275z"
    />
    <polyline
      style="fill:#E84242;"
      points="260.376,259.911 260.376,245.19 191.874,226.922 123.371,245.19 123.371,404.115 
	260.376,404.115 260.376,290.965 "
    />
    <polyline
      style="fill:#82DCC7;"
      points="271.373,259.911 271.373,245.19 202.871,226.922 134.368,245.19 134.368,404.115 
	271.373,404.115 271.373,290.965 "
    />
    <path
      style="fill:#707070;"
      d="M202.87,248.235c-5.044,0-9.134-4.09-9.134-9.134V112.778c0-5.044,4.09-9.134,9.134-9.134
	s9.134,4.09,9.134,9.134v126.322C212.004,244.145,207.916,248.235,202.87,248.235z"
    />
    <polygon
      style="fill:#FED159;"
      points="237.577,295.796 237.577,281.082 210.227,281.082 210.227,262.128 195.514,262.128 
	195.514,281.082 168.164,281.082 168.164,328.965 222.863,328.965 222.863,347.419 168.164,347.419 168.164,362.134 
	195.514,362.134 195.514,381.086 210.227,381.086 210.227,362.134 237.577,362.134 237.577,314.251 182.878,314.251 
	182.878,295.796 "
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
